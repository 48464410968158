<template>
  <div class="row">
    <div class="col">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"></Confirm>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <transition name="slide">
        <div class="card border-warning">
          <div class="card-header">
            <strong>Designación de Materias</strong>
            <div class=" card-header-actions">
              <form class="form-inline">
                <input id="user" type="hidden" value="<?php echo $user->id ?>"/>
                <b>Gestión: </b>
                <select v-model="gestion" class="form-control mr-2 ml-1"
                        @change="actualizarDatos($event)">
                  <template v-for="(option,key) in gestiones">
                    <optgroup :label="key">
                      <option v-for="suboption in option" :value="suboption.value">
                        {{ suboption.label }}
                      </option>
                    </optgroup>
                  </template>
                </select>

                <b>Filas por p&aacute;gina: </b>
                <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                        v-model="filasPorPagina">
                  <option v-for="option in sizeoptions" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>

                <button class="btn btn-outline-dark mr-2" type="button"
                        @click="borrarFiltroBtnOnClick()">
                  <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                  Quitar Filtro
                </button>

<!--                <button class="btn btn-outline-primary ml-2" data-html="true"-->
<!--                        data-toggle="tooltip" title="Inscribir un <b>nuevo</b> estudiante" type="button"-->
<!--                        @click="nuevoBtnOnClick()">-->
<!--                  <font-awesome-icon icon="fa-plus"/>-->
<!--                  Nuevo-->
<!--                </button>-->

                <button class="btn btn-outline-success ml-2" data-html="true"
                        data-toggle="tooltip"
                        title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                        type="button"
                        @click="excelBtnOnClick()">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Exportar
                </button>

                <button v-if="hasRole('configurar-desig-materia') || hasRole('admin')" class="btn btn-outline-dark ml-2"
                        type="button"
                        @click="parametros.gestion=gestion;listarParametrosEditar(gestion);modalConfig = true">
                  <font-awesome-icon icon="fa-solid fa-gear"/>
                  Configurar
                </button>
              </form>
            </div>
          </div>
          <div class="card-body p-0 pr-1">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'" :columnsresize="true"/>
          </div>
        </div>
      </transition>
    </div>
    <CModal
        :centered="true"
        :closeOnBackdrop="false"
        :fade="true"
        :no-close-on-backdrop="true"
        :show.sync="modalConfig"
    >
      <template>
        <form ref="frmParametros" novalidate>
          <div class="row mb-2">
            <div class="col-6 text-center">
              <strong>Aplicar desde la Gestión</strong>
            </div>
            <div class="col-6">
              <select class="form-control pl-1 pr-1"
                      v-model="parametros.gestion" required="required"
                      @change="listarParametrosEditar(parametros.gestion)"
              >
                <option value="" selected>:: SELECCIONAR ::</option>
                <template v-for="(option,key) in gestiones">
                  <optgroup :label="key">
                    <option v-for="suboption in option" :value="suboption.value"
                            :class="parametros.gestiones.hasOwnProperty(suboption.value)?'text-danger text-bold':''">
                      {{ suboption.label }} {{parametros.gestiones.hasOwnProperty(suboption.value)?' (Parametros)':''}}
                    </option>
                  </optgroup>
                </template>
              </select>
            </div>
          </div>
          <hr/>
          <div class="row pl-2 mb-2">
            <div class="col-8 text-center">
              <strong>Cantidad de Modificaciones permitidas</strong>
            </div>
            <div class="col-4">
              <input type="text" v-model="parametros.cantidad_modificaciones" class="form-control text-center" required>
            </div>
          </div>
          <div class="row pl-2 pt-2">
            <div class="col-8 text-center">
              <strong>Fecha inicial</strong>
            </div>
            <div class="col-4">
              <input type="date" v-model="parametros.fecha_ini" class="form-control text-center"
                     required>
            </div>
          </div>
          <div class="row pl-2 pt-2">
            <div class="col-8 text-center">
              <strong>Fecha Final</strong>
            </div>
            <div class="col-4">
              <input type="date" v-model="parametros.fecha_fin" class="form-control text-center"
                     required>
            </div>
          </div>
          <div class="row pl-2 pt-2">
            <div class="col text-center">
              <button type="button" @click="grabarParametros" class="btn btn-primary">
                Grabar parámetros
              </button>
            </div>
          </div>
        </form>
      </template>
      <template #header>
        <h4>Parámetros de Configuración <br/>Designación de materias:
        </h4>
        <CButtonClose @click="modalConfig = false"/>
      </template>
      <template #footer-wrapper><span></span></template>
    </CModal>
  </div>
</template>

<script>
import axios from 'axios'
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import Confirm from "@/views/notifications/Confirm";
import ModalVideo from "@/views/notifications/ModalVideo";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'Alumnos',
  components: {
    ModalVideo,
    Confirm,
    ModalPDF,
    Alerts,
    Toast,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      darkModal: false,
      modalConfig: false,
      items: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      sedeSeleccionada: localStorage.sedeSeleccionada,
      gestiones: [],
      gestion: 0,
      parametros: {
        gestion:'',
        gestiones:[],
        grupo: 'designa_materias',
        cantidad_modificaciones: 2,
        fecha_ini: '',
        fecha_fin: ''
      },
      parametrosDocente: {
        gestion:'',
        gestiones:[],
        grupo: 'designa_materias',
        cantidad_modificaciones: 2,
        fecha_ini: '',
        fecha_fin: '',
        puede_editar:false
      },
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          modalPdf.$router.go(-1)
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Código Alumno', datafield: "codigo_alumno", width: '6%', cellsalign: 'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Código<br />Alumno</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Código Alumno"});
          }
        },
        {
          text: 'Apellido1', datafield: "apellido1", width: '10%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Primer<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido1"});
          }
        },
        {
          text: 'Apellido2', datafield: "apellido2", width: '10%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Segundo<br />apellido</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Apellido2"});
          }
        },
        {
          text: 'Nombres', datafield: "nombres", width: '12%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Nombres"});
          }
        },
        {
          text: 'Carrera', datafield: "carrera", width: '19%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Carrera"});
          }
        },
        {
          text: 'Estado', datafield: "estado", width: '10%',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Carrera"});
          }, cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<div class="mt-2 text-center">'+(value=='APROBADO'?'<strong class="text-success">APROBADO</strong>':'<strong class="text-warning">ELABORADO</strong>')+'</div>';
          }
        },
        {
          text: '', width: '8%', cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            if(rowdata.estado=='APROBADO') {
              if(modalPdf.parametrosDocente.puede_editar) {
                return '<a class="btn btn-primary btn-block m-1 me-3 mr-3" href="/#/docentedesigmateria/' + rowdata.id + '/' + rowdata.codigo_alumno + '/editarantiguo" title="Editar inscripcion de alumno nuevo">Editar</a>';
              }else{
                return '';
              }
            }else{
              return '';
            }
          }, filterable: false, sortable: false
        },
        {
          text: "",datafield: '', width: '12%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: "btn-green",
          renderer: function () {
            return '';
          },
          cellsrenderer: function () {
            return "Designación PDF";
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            modalPdf.$refs.alert.show('Descargando documento');
            axios.get(
                address + '/api/docentedesigmateria/' + rowData.id + '/designacionpdf?token=' + localStorage.getItem("api_token"),
                {responseType: 'blob'}
            )
                .then((response) => {
                  modalPdf.$refs.alert.hide();
                  modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
                })
                .catch(function (error) {
                  modalPdf.$refs.alert.hide();
                  modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                });
          }
        },
        {
          text: "", datafield: 'eliminar', width: '10%', columntype: "button",
          filterable: false, sortable: false,
          cellclassname: function(row, columnfield, value) {
            if(modalPdf.parametrosDocente.puede_editar) {
              let estado = modalPdf.$refs.gridSystem.getcellvalue(row, 'estado');
              if (estado == 'APROBADO') {
                return 'btn-red';
              } else {
                return 'btn-hidden';
              }
            }else{
              return 'd-none';
            }
          },
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return 'Eliminar';
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            if (rowData.estado == 'APROBADO') {
              modalPdf.$refs.confirm.confirm('¿Esta sugur@ de eliminar el registro?', '', function () {
                modalPdf.$refs.alert.show('Eliminando registro');
                axios.get(
                    address + '/api/docentedesigmateria/' + rowData.id + '/eliminarinscripcionantiguo?token=' + localStorage.getItem("api_token")
                )
                    .then((response) => {
                      modalPdf.$refs.alert.hide();
                      modalPdf.$refs.gridSystem.updatebounddata();
                    })
                    .catch(function (error) {
                      modalPdf.$refs.alert.hide();
                      modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                    });
              })
            }
          }
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = '';
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'codigo_alumno', type: "string"},
        {name: 'apellido1', type: "string"},
        {name: 'apellido2', type: "string"},
        {name: 'nombres', type: "string"},
        {name: 'carrera', type: "string"},
        {name: 'gestion', type: "string"},
        {name: 'estado', type: "string"},
        {name: 'documentos', type: "string"},
        {name: 'compromisos', type: "string"},
        {name: 'contratos', type: "string"},
        {name: 'hojaruta', type: "string"},
        {name: 'editar', type: "string"},
        {name: 'eliminar', type: "string"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'codigo_alumno',
      sortcolumn: 'estado',
      sortdirection: 'desc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    eliminarUser(id) {
      let self = this;
      let userId = id;
      axios.post(this.$apiAdress + '/api/alumnos/' + id + '?token=' + localStorage.getItem("api_token"), {
        _method: 'DELETE'
      })
          .then(function (response) {
            modalPdf.$refs.mensajeToast.makeToast('', 'Successfully eliminard user.', 'danger');
            self.getAlumnos();
          })
          .catch(function (error) {
            console.log(error);
            modalPdf.$router.go(-1)
          });
    },
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));

      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    nuevoBtnOnClick: function () {
      this.$router.push({path: '/docentedesigmateria/0/0/editarantiguo'});
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    },
    actualizarDatos: function (event) {
      axios.get(this.$apiAdress + '/api/docentedesigmateria/' + localStorage.sedeSeleccionada + '/' + this.gestion + '/listarparametros?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            modalPdf.parametrosDocente = response.data;
            sourceLst.url = modalPdf.$apiAdress + '/api/docentedesigmateria/' + modalPdf.gestion + '/' + localStorage.sedeSeleccionada + '/designados?token=' + localStorage.getItem("api_token");
            modalPdf.$refs.gridSystem.clearfilters();
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf('admin') >= 0) {
          return true
        }
        return roles != null && roles.indexOf(roleUser) >= 0;
      }
    },
    listarParametrosEditar: function (gestion) {
      axios.get(this.$apiAdress + '/api/docentedesigmateria/' + localStorage.sedeSeleccionada + '/' + gestion + '/listarparametros?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            modalPdf.parametros = response.data;
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    grabarParametros: function (event) {
      if (!modalPdf.$refs.frmParametros.checkValidity()) {
        modalPdf.$refs.frmParametros.classList.add('was-validated');
      } else {
        modalPdf.modalConfig = false;
        modalPdf.$refs.alert.show('Grabando materias asignadas');
        axios.post(this.$apiAdress + '/api/docentedesigmateria/' + localStorage.sedeSeleccionada + '/actualizaparametros?token=' + localStorage.getItem("api_token"),
            modalPdf.parametros
        )
            .then(function (response) {
              modalPdf.$refs.mensajeToast.makeToast('', 'Parámetros actualizados satisfactoriamente.', 'success',);
              modalPdf.actualizarDatos(event);
            })
            .catch(function (error) {
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            })
            .finally(function () {
              modalPdf.$refs.alert.hide();
              modalPdf.modalConfig = true;
            });
      }
    },
  },
  updated() {
    if (sourceLst.url === '') {
      this.actualizarDatos();
    }
  },
  mounted() {
    modalPdf = this;
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
    const boxes = document.querySelectorAll('.btn-hidden');
    boxes.forEach(box => {
      box.innerHTML = '';
    });
    axios.post(this.$apiAdress + '/api/factura/' + localStorage.sedeSeleccionada + '/opcionesfactura?token=' + localStorage.getItem("api_token"),
        {'tipo_lista':'designa_materias'}
    )
        .then(function (response) {
          let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
          modalPdf.gestiones = response.data.gestiones;
          modalPdf.gestion = response.data.mesActual;
        }).catch(function (error) {
      console.log(error);
      modalPdf.$router.go(-1)
    });
  },
}
</script>